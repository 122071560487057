@import '../../index.scss';


.forgot-password-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 10px;
  width: 50%;
  height: 50%;
  padding: 50px;

  @include respond-to('phone') {
    padding: 20px;
    width: 85%;
  }
}

.forgot-password-input {
  width: 60%;

  @include respond-to('small-tablet') {
    width: 100%;
  }

  @include respond-to('phone') {
    width: 100%;
  }
}