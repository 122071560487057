@import "../../../index";

.autocomplete {
  position: relative;
  width: 30vw;

  .iconLoupe {
    position: absolute;
    top: 25%;
    right: 3%;
    width: 20px;
    pointer-events: none;
    display: inline;
  }

  .MuiAutocomplete-inputRoot {
    border-radius: 20px;
  }

  @include respond-to('phone') {
    width: 88vw;
  }

  @include respond-to('small-tablet') {
    width: 30vw;
  }
}