@import '../../index.scss';


.legalPage {
    background-color: $primary-color;
    padding-inline: 4vw;
    padding-top: 0.5vh;
    padding-bottom: 2vh;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: 15px;

  @include respond-to('small-tablet') {
    padding-top: 1.5vh;
  }
}

.subTitle {
  margin-bottom: 5px;
  margin-top: 20px;
}