.switch-container {
  display: flex;
  padding: 5px;
  border: none;
  border-radius: 20px;
  background-color: #efefef;
  height: 40px;
}

.switch-btn {
  padding: 10px;
  border-radius: 20px;
  cursor: pointer;

  p {
    margin: 0;
  }
}

.switch-btn.active {
  background-color: black;
  color: white;
}