@import '../../index.scss';

.containerPage {

  .headerMarket {
    align-items: center;
    display: flex;

    h1 {
      margin-top: 5px;

      @include respond-to('desktop') {
        margin-top: 0;
      }

      @include respond-to('large-desktop') {
        margin-top: 0;
      }
    }

    @include respond-to('phone') {
      flex-direction: column;
      gap: 10px;
    }
  }


  .pageMarket {
    background-color: $primary-color;
    padding-inline: 4vw;
    padding-block: 4vh;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: 15px;


    .trends {

      @include respond-to('phone') {
        display: none;
      }

      .containerTrends {
        justify-content: space-around;
        margin-bottom: 40px;

        @include respond-to('tablet') {
          margin-bottom: 20px;
        }
      }

      .boxTrends {
        width: 45%;
      }
    }
  }

  .pagination-top {
    @include respond-to('phone') {
      display: none;
    }
  }
}

