@import '../../index.scss';

h3 {
  margin-bottom: 4px;
}

h1 {
  margin-bottom: 10px;
}

.subPhrase {
  opacity: 0.8;
  margin-top: 0;
}

.section {
  @include respond-to('phone') {
    flex-direction: column;

    Button {
      width: 100%;
    }
  }
}

.section-field {
  width: 45%;

  @include respond-to('phone') {
    width: 100%;
  }
}
