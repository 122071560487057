@import "utils/utils.scss";

@mixin respond-to($breakpoint) {
  @if $breakpoint == "phone" {
    @media (max-width: 600px) {
      @content;
    }
  } @else if $breakpoint == "small-tablet" {
    @media (min-width: 601px) and (max-width: 1000px) {
      @content;
    }
  } @else if $breakpoint == "tablet" {
    @media (min-width: 1001px) and (max-width: 1440px) {
      @content;
    }
  } @else if $breakpoint == "desktop" {
    @media (min-width: 1441px) and (max-width: 1920px) {
      @content;
    }
  } @else if $breakpoint == "large-desktop" {
    @media (min-width: 1921px) {
      @content;
    }
  }
}

// COULEURS
$primary-color: #ffffff;
$secondary-color: #f8f8f8;
$color-3: #9f9f9f;

:export {
  primaryColor: $primary-color;
  secondaryColor: $secondary-color;
  color-3: $color-3;
}

html,
body {
  font-family: Gabarito !important;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #fafafa;
}

#root {
  height: 100%;
}

.App {
  height: 100%;
  display: flex;
  flex-direction: column;
}

//FONTS

@font-face {
  font-family: Unbounded;
  src: url("assets/fonts/Unbounded/static/Unbounded-SemiBold.ttf")
    format("truetype");
}

@font-face {
  font-family: Gabarito-Bold;
  src: url("assets/fonts/Gabarito/static/Gabarito-Bold.ttf") format("truetype");
}

@font-face {
  font-family: Gabarito-SemiBold;
  src: url("assets/fonts/Gabarito/static/Gabarito-SemiBold.ttf")
    format("truetype");
}

@font-face {
  font-family: Gabarito;
  src: url("assets/fonts/Gabarito/static/Gabarito-Regular.ttf")
    format("truetype");
}

@font-face {
  font-family: simpletype;
  src: url("assets/fonts/simpletype/Simpletype.ttf") format("truetype");
}

.Gabarito-SemiBold {
  font-family: Gabarito-SemiBold;
}

.Gabarito-Bold {
  font-family: Gabarito-Bold;
}

.Gabarito {
  font-family: Gabarito;
}

.simpletype {
  font-size: 100px;
  font-family: simpletype;

  @include respond-to('phone') {
    font-size: 50px;
  }
}
