@import '../../index.scss';

.card {
  width: 100%;
  padding: 4vh;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
}
.podium{
  text-align: center;
  padding: 6px;
  position: absolute;
  width: 100px;
  display: flex;
  flex-direction: column;

  p{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.podium.n1{
  right: 46%;

}

.podium.n2{
  left: 27%;
  top: 18%;
}

.podium.n3{
  bottom: 56%;
  right: 28%;
}