.alone-cell{
    text-align: center !important; /* Pour centrer le texte horizontalement */
    vertical-align: middle !important;
}
.gold{
    background-color: #FFD700;
}
.silver{
    background-color: #C0C0C0;
}
.bronze{
    background-color: #CD7F32;
}