@import "../../index";

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 75px;
  height: 75px;
  border-radius: 50%;
  border-left-color: #000000;
  animation: spin 0.5s ease-in infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.containerSpinner {
  padding-block: 5vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}