@import '../../index.scss';

.pageStock {
  background-color: $primary-color;
  padding-inline: 4vw;
  padding-block: 4vh;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  padding-bottom: 5vh;
  border-radius: 15px;

  .stockInfos {
    padding-inline: 5%;
  }

  .stockDescription {
    text-align: justify;
  }

  .containerLineChart {
    height: 500px;

    @include respond-to('tablet') {
      height: 420px;
    }

    @include respond-to('small-tablet') {
      height: 420px;
    }

    @include respond-to('phone') {
      height: 420px;
    }
  }

  .boxRange {
    cursor: pointer;
    padding-block: 5px;
    width: 60px;
    background-color: #dedede;
    text-align: center;
    border-radius: 10px;

  }

  .focus {
    background-color: #b4b4b4;

  }

}

.transaction-widget {
  flex-grow: 1;
  padding-left: 2%;

  @include respond-to('phone') {
    margin-bottom: 20px;
  }
}


.containerSections {

  .spacer-mobile {
    display: none;

    @include respond-to('phone') {
      display: block;
    }
  }

  @include respond-to('phone') {
    flex-wrap: wrap;
  }
}


.containerStockGraph {
  width: 70%;

  @include respond-to('phone') {
    width: 100%;
  }
}

.stock-graph {
  height: 500px;

  @include respond-to('tablet') {
    height: 420px;
  }

  @include respond-to('small-tablet') {
    height: 420px;
  }

  @include respond-to('phone') {
    height: 420px;
  }
}

.info-container {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;

  .label {
    font-family: Gabarito-Bold;
    display: inline-block;
  }

  .item {
    display: inline-block;
  }
}

.stock-info {
  width: 70%;

  @include respond-to('phone') {
    width: 100%;
  }

  @include respond-to('small-tablet') {
    width: 100%;
  }

  @include respond-to('small-tablet') {
    width: 100%;
  }
}


.stock-header {

  .stock-image {
    width: 50px;
    height: 50px;

    @include respond-to('small-tablet') {
      width: 40px;
      height: 40px;
    }

    @include respond-to('phone') {
      width: 40px;
      height: 40px;
    }
  }

  .name {
    margin-left: 15px;
  }

  .ticker {
    color: #b4b4b4;

    @include respond-to('small-tablet') {
      display: none;
    }

    @include respond-to('phone') {
      display: none;
    }
  }

  .favorite {
    width: 30px;
    height: 30px;
    cursor: pointer;

    @include respond-to('small-tablet') {
      width: 20px;
      height: 20px;
    }

    @include respond-to('phone') {
      width: 20px;
      height: 20px;
    }
  }
}