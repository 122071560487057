@import "../../../index";

.tableTrends {
  .paper {
    padding-block: 13px;
    overflow-y: scroll;
    scrollbar-width: none;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 250px;

    @include respond-to('tablet') {
      padding-block: 5px;
      height: 200px;
    }

    @include respond-to('small-tablet') {
      height: 150px;
    }
  }

  .tableHeader {
    font-size: 16px !important;
    font-family: Gabarito-SemiBold !important;
  }

  .tableCell {
    cursor: default !important;
    padding: 5px !important;
    padding-left: 30px !important;

    @include respond-to('tablet') {
      padding-left: 20px !important;
    }

    @include respond-to('small-tablet') {
      padding-left: 15px !important;
    }
  }

  .tableContainer {
    scrollbar-width: none;
  }

  .ticker {
    font-family: Gabarito-Bold !important;
  }


  #volume, #price {
    @include respond-to('tablet') {
      display: none;
    }

    @include respond-to('desktop') {
    }

    @include respond-to('large-desktop') {
    }
  }

  #logo {

    @include respond-to('small-tablet') {
      display: none;
    }
  }

  .ticker-name {
    @include respond-to('small-tablet') {
      display: none;
    }

    @include respond-to('phone') {
      display: none;
    }
  }

  .ticker-only {
    @include respond-to('tablet') {
      display: none;
    }

    @include respond-to('desktop') {
      display: none;
    }

    @include respond-to('large-desktop') {
      display: none;
    }
  }

}