@import '../../../index.scss';


.homePage {
  z-index: 0;
}

.bg-pc {
  position: absolute;
  bottom: 0;
  right: 0;
  margin-top: 5%;
  margin-bottom: 12vh;


  img {

    @include respond-to('phone') {
      display: none;
    }

    @include respond-to('small-tablet') {
      display: none;
    }

    @include respond-to('tablet') {
      width: 800px;
    }

    @include respond-to('desktop') {
      width: 1100px;

    }

    @include respond-to('large-desktop') {
      width: 1300px;

    }
  }
}


.paper-1 {
  margin-top: 14vh;
  margin-left: 3vw;
  width: 20vw;
  height: 20vh;
  padding: 3% 3% 2% 2%;

  @include respond-to('phone') {
    display: none
  }

  @include respond-to('small-tablet') {
    display: none
  }

}

.paper-2 {
  position: absolute;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: -10vh;
  margin-left: 10vw;
  width: 30vw;
  height: 15vw;
  overflow: hidden;

  .doughnutChart {
    height: 11vw !important;
    width: 11vw !important;
    margin: 1vw;
  }

  @include respond-to('phone') {
    display: none
  }

  @include respond-to('small-tablet') {
    display: none
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.initial-load-animation {
  animation: fadeIn 2s ease-out;
}


.bg-svg {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}

.buttonsHomePage {
  display: none;

  .button {

    @include respond-to('phone') {
      font-size: 16px;
      width: 300px;
      padding: 10px 0px;
    }

    @include respond-to('small-tablet') {
      font-size: 18px !important;
      width: 500px !important;
      padding: 12px 0px;
    }

  }

  .custom-link {
    margin-bottom: 15px;
  }


  @include respond-to('phone') {
    margin-top: 6%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

  }

  @include respond-to('small-tablet') {
    margin-top: 6%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

  }

}

.title-long {
  display: none;

  @include respond-to('small-tablet') {
    display: block;
    margin-top: 12%;
  }
}

.title-letter {
  display: none;

  @include respond-to('phone') {
    display: block;
    margin-top: 5%;
    font-size: 150px !important;
  }
}


.homePageFooter {
  position: absolute;
  bottom: 0;
  word-wrap: break-word;
  width: 50%;
  margin-left: 1%;

  @include respond-to('phone') {
    position: relative;
    width: 90%;
    margin-left: 5%;
    text-align: center;
    margin-top: 12%;
  }

  @include respond-to('small-tablet') {
    position: relative;
    width: 90%;
    margin-left: 5%;
    text-align: center;
    margin-top: 12%;
  }

  .slogan {
    font-family: Gabarito-Bold;
    line-height: 0.9;
    margin-bottom: 5%;

    @include respond-to('phone') {
      font-size: 40px;
    }


    @include respond-to('tablet') {
      font-size: 50px;
    }

    @include respond-to('desktop') {

      font-size: 70px;

    }

    @include respond-to('large-desktop') {
      font-size: 105px;

    }


  }

  .phraseAccroche {
    @include respond-to('phone') {
      font-size: 17px;
    }

    @include respond-to('tablet') {
      font-size: 20px;
    }

    @include respond-to('desktop') {
      font-size: 25px;

    }

    @include respond-to('large-desktop') {
      font-size: 35px;
    }

    font-family: Gabarito;
    line-height: 0.9;
    margin-bottom: 3%;
  }
}

@keyframes flottant {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-8px);
  }
}

.paper-1,
.paper-2 {
  animation: flottant 3s ease-in-out infinite;
}

.buttonDemo {
  @include respond-to('phone') {
    display: none !important;
  }

  @include respond-to('small-tablet') {
    display: none !important;
  }
}

.demoIcon {
  height: 30px;
  width: 30px;

  @include respond-to('phone') {
    height: 19px;
    width: 19px;
  }
}