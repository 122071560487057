@import '../../../index.scss';

.password-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  border-radius: 10px;
  width: 50%;
  padding: 50px;

  @include respond-to('phone') {
    padding: 20px;
    width: 85%;
  }
}