@import "../../index";

.pageAbout {
  background-color: $primary-color;
  padding-inline: 4vw;
  padding-top: 5vh;
  padding-bottom: 8vh;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 15px;

  @include respond-to('phone') {
    padding-inline: 0;
  }

  @include respond-to('small-tablet') {
    padding-inline: 0;
  }
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.scroller {
  display: flex;
  width: calc((100px + 80px) * 4);
  animation: scroll 15s linear infinite;
}

.scroller div {
  margin-right: 80px;
}

.scroller div:last-child {
  margin-right: 0;
}

.profil-container {
  gap: 50px
}

.paragraph {
  width: 50%;
  margin-top: 0;
  margin-bottom: 0;

  @include respond-to('phone') {
    width: 90%;
  }

  @include respond-to('small-tablet') {
    width: 80%;
  }
}

.socials {
  gap: 10px;
}
