@import "../../../index.scss";

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.actions-items {
  display: grid;
  grid-template-columns: 15% 1fr minmax(15%, auto); /* Utilisation de minmax() */
  gap: 10px;
  align-items: center;
  width: 100%;
  margin-block: 5px;
}

.containerWalletName h1, .containerWalletName h3 {
  margin: 0;
  padding: 0;

  @include respond-to('tablet') {
    margin-top: 10px;
  }

  @include respond-to('small-tablet') {
    margin-top: 5px;
  }
}

.content {
  flex-grow: 1;
  margin: 0 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  p {
    margin-block: 2px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.cards {
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  height: 100%;
  max-height: 100%;
  box-sizing: border-box;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.rightSide {
  max-height: 700px;
  overflow-y: scroll;
  width: 35%;
  margin-left: 5%;

  @include respond-to('tablet') {
    max-height: 580px;
    max-width: 350px;
  }

  @include respond-to('small-tablet') {
    max-height: 650px;
    max-width: 350px;
  }

  @include respond-to('phone') {
    width: 100%;
    max-height: none;
  }

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    background: #d5d5d5;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #d5d5d5;
  }

  scrollbar-width: thin;
  scrollbar-color: #d5d5d5 transparent;
}

.container-graph {
  padding: 0 10px;
  width: 100%;

  @include respond-to('tablet') {
    padding: 0 0;
  }

  @include respond-to('small-tablet') {
    padding: 0 0;
  }

  @include respond-to('phone') {
    padding: 0 0;
  }
}

.demo {
  margin-top: 25px;

  @include respond-to('small-tablet') {
    margin-top: 35px;
  }

  @include respond-to('desktop') {
    margin-top: 0;
  }

  @include respond-to('large-desktop') {
    margin-top: 0;
  }
}

.demoBanner {
  border-radius: 15px 15px 0 0;
  text-align: center;
  padding-top: 7px;
  padding-bottom: 7px;
}

.wallet-evolution {
  display: block;

  @include respond-to('tablet') {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  @include respond-to('small-tablet') {
    h1 {
      margin-top: 5px;
    }
  }
}

.wallet-switch-btn {
  @include respond-to('tablet') {
    margin-left: 30px;
  }
}

.wallet-graph {
  height: 500px;

  @include respond-to('tablet') {
    height: 420px;
  }

  @include respond-to('small-tablet') {
    height: 420px;
  }

  @include respond-to('phone') {
    height: 420px;
  }
}

.wallet-camembert {
  height: 450px;
  padding-block: 25px;

  @include respond-to('tablet') {
    height: 400px;
  }

  @include respond-to('small-tablet') {
    height: 400px;
  }

  @include respond-to('phone') {
    height: 375px;
  }
}
