@import '../../index.scss';

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.image-container {
  flex: 1;
}

.containerInputAuth {
  @include respond-to('phone') {
    width: 100% !important;
  }
}

.form-container {
  display: flex;
  height: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  @include respond-to('phone') {
    width: 100%;
  }
}

.form-group {
  margin-bottom: 1rem;

}

.leftSideAuth {
  overflow: hidden;
  max-width: 40%;

  .imageBg {
    transform: translateY(3%);
    height: 100vh;
  }


  @include respond-to('phone') {
    display: none;
  }
}

.acceptance {
  margin-bottom: 8px;

  div {
    margin-left: 5px;
  }

  .legalTerms {
    cursor: pointer;
    text-decoration: underline;
  }
}

.forgotPassword {
  width: 50%;

  @include respond-to('phone') {
    width: 100%;
  }

  .forgotPassword-text {
    font-size: 13px;
  }
}